import React, {useState, useEffect} from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  CardActions,
  Card,
  Button,
  CardContent,
  TextField,
  IconButton,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Switch,
} from "@material-ui/core";

import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import Copyright from "../../components/Copyright";
import Layout from "../../components/Layout";
import UltimateBack from "./UltimateBack";
import UltimateFront from "./UltimateFront";
import PalettePicker from "../../components/PalettePickers/UniversalPicker";
import Link from "../../components/Link";

import {
  baseTab,
  patchTab,
  stitchingTab,
  contrastTab,
} from "../../utils/palettePickerTabs";
import {addToDb} from "../../utils/db";
import {COLORS} from "../../utils/colors";
import {navigate} from "gatsby-link";

import PocketImg from "../../images/pocket.jpg";
import StripesImg from "../../images/stripes.jpg";
import logoPocket from "../../images/logo_pocket.png";
import mat1 from "../../images/mat1.jpg";
import mat2 from "../../images/escelade.jpg";
import logoPocketBack from "../../images/logo_back_pocket.png";

import {PRICE} from "../../utils/prices";

export default function UltimateTrousers() {
  const classes = useStyles();

  const [lightContrast, setLightContrast] = useState(COLORS.white.value);
  const [darkContrast, setDarkContrast] = useState(COLORS.black.value);
  const [stitching, setStitching] = useState(COLORS.black.value);
  const [patchColor, setPatchColor] = useState(COLORS.black.value);
  const [azomo, setAzomo] = useState(COLORS.white.value);
  const [quantity, setQuantity] = useState(30);
  const [price, setPrice] = useState(PRICE.ultimatetrousers);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showBack, setShowBack] = useState(false);

  const [strips, setStrips] = useState(false);

  const [pocketLogo, setPocketLogo] = useState(false);

  const [backPocketLogo, setBackPocketLogo] = useState(false);

  const [selectedPart, setSelectedPart] = useState("base");

  const [material, setMaterial] = useState("Ripstop");

  const [corduraPrice, setCorduraPrice] = useState(0);

  useEffect(() => {
    const colorPrice =
      patchColor === COLORS.blackProtective.value ||
      patchColor === COLORS.whiteProtective.value ||
      patchColor === COLORS.grayProtective.value
        ? quantityCheck() * PRICE.cordura
        : 0;

    setCorduraPrice(colorPrice);

    setTotalPrice(Number(quantityCheck()) * Number(price) + colorPrice);
  }, [price, quantity, strips, pocketLogo, backPocketLogo, patchColor, corduraPrice]);

  const quantityCheck = () => {
    return quantity >= 30 ? quantity : 30;
  };

  const handleStrips = () => {
    setStrips(!strips);

    if (strips === false) {
      setPrice(price + PRICE.reflectivestrips);
    }
    if (strips === true) {
      setPrice(price - PRICE.reflectivestrips);
    }
  };

  const handlePocketLogo = () => {
    setPocketLogo(!pocketLogo);

    if (pocketLogo === false) {
      setPrice(price + PRICE.logo);
    }
    if (pocketLogo === true) {
      setPrice(price - PRICE.logo);
    }
  };

  const handleBackPocketLogo = () => {
    setBackPocketLogo(!backPocketLogo);

    if (backPocketLogo === false) {
      setPrice(price + PRICE.logo);
    }
    if (backPocketLogo === true) {
      setPrice(price - PRICE.logo);
    }
  };

  const handleMaterial = (event) => {
    setMaterial(event.target.value);
  };

  const extras = [
    {
      name: "Leuchtstreifen an den Beinen",
      img: StripesImg,
      selected: false,
      val: strips,
      fun: handleStrips,
    },
  ];

  const stickerOptions = [
    {
      name: "Stickerei auf Beintasche links",
      img: logoPocket,
      selected: false,
      val: pocketLogo,
      fun: handlePocketLogo,
    },
    {
      name: "Stickerei auf Geässtsache links",
      selected: false,
      img: logoPocketBack,
      val: backPocketLogo,
      fun: handleBackPocketLogo,
    },
  ];

  const handleAdd = (e) => {
    let extraOptions = [];
    if (strips) extraOptions.push("Leuchtstreifen an den Beinen");

    if (pocketLogo) extraOptions.push("Stickerei auf Beintasche links");

    if (backPocketLogo) extraOptions.push("Stickerei auf Geässtsache links");

    extraOptions.push("Material: " + material);

    e.preventDefault();
    addToDb(
      lightContrast,
      darkContrast,
      stitching,
      patchColor,
      azomo,
      quantityCheck(),
      price,
      extraOptions,
      totalPrice,
      "ultimate",
      "Hose Ultimate",
      "pants",
      true,
      true
    );
    navigate("/cart");
  };

  return (
    <Layout>
      {/* MAIN GRID START*/}
      <Grid
        container
        align="center"
        justify="center"
        direction="row"
        className={classes.root}
      >
        <Grid align="center" item sm={1} lg={1} md={1} xl={1} xs={1}></Grid>
        <Grid
          style={{background: "white"}}
          align="center"
          item
          sm={12}
          lg={9}
          md={9}
          xl={9}
          xs={12}
        >
          <Grid align="center" item sm={12} lg={12} md={12} xl={12} xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className={classes.preview}>
                <UltimateFront
                  lightContrast={COLORS.white.value}
                  darkContrast={COLORS.black.value}
                  stitching={COLORS.black.value}
                  patchColor={COLORS.black.value}
                  azomo={COLORS.white.value}
                  preview={true}
                />
              </div>
              <div className={classes.preview}>
                <UltimateBack
                  lightContrast={COLORS.white.value}
                  darkContrast={COLORS.black.value}
                  stitching={COLORS.black.value}
                  patchColor={COLORS.black.value}
                  azomo={COLORS.white.value}
                  preview={true}
                />
              </div>
            </div>
          </Grid>
          <Grid className={classes.specs} item sm={12} lg={12} md={12} xl={12} xs={12}>
            <Typography
              style={{background: "#F1F1F1"}}
              noWrap
              align="center"
              variant="h4"
            >
              Ultimate Hose
            </Typography>
            <Grid align="center" container>
              <Grid
                style={{background: "white"}}
                align="left"
                item
                sm={12}
                lg={6}
                md={6}
                xl={6}
                xs={12}
              >
                <Typography className={classes.specsText} variant="subtitle1">
                  <br />- Modisch geschnitte Arbeitshose
                  <br />- Funktional und robust
                  <br />- Erhältliche Stoffe: Mischgewebe 2X – Stretch
                  <br />- Diverse Applikationen gemäss Abbildung
                  <br />- Knieverstärkung (Cordura)
                </Typography>
              </Grid>

              <Grid
                style={{background: "white"}}
                align="left"
                item
                sm={12}
                lg={6}
                md={6}
                xl={6}
                xs={12}
              >
                <Typography className={classes.specsText} variant="subtitle1">
                  <br />- Knietaschen aussen
                  <br />- Diverse Veredelungsmöglichkeiten
                  <br />- Mindestproduktionsmenge 30 Stück
                  <br />- Grössen 38 bis 64
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.specsText} align="center" variant="h4">
              Farbschema
            </Typography>
            <Divider style={{marginTop: "10px", paddingTop: "3px"}} />
          </Grid>
          <Grid style={{paddingLeft: "5%", paddingRight: "5%"}} container>
            <Grid
              className={classes.selectionPreview}
              item
              sm={12}
              lg={6}
              md={6}
              xl={6}
              xs={12}
            >
              <IconButton
                className={classes.rButton}
                onClick={(e) => setShowBack(!showBack)}
              >
                <RotateLeftIcon className={classes.rotateButton} />
              </IconButton>

              {showBack ? (
                <UltimateBack
                  lightContrast={lightContrast}
                  darkContrast={darkContrast}
                  stitching={stitching}
                  patchColor={patchColor}
                  azomo={azomo}
                  getDataFromChild={setSelectedPart}
                  preview={false}
                />
              ) : null}
              {!showBack ? (
                <UltimateFront
                  lightContrast={lightContrast}
                  darkContrast={darkContrast}
                  stitching={stitching}
                  patchColor={patchColor}
                  azomo={azomo}
                  getDataFromChild={setSelectedPart}
                  preview={false}
                />
              ) : null}
            </Grid>
            <Grid
              className={classes.selectionPreview}
              item
              sm={12}
              lg={6}
              md={6}
              xl={6}
              xs={12}
            >
              <PalettePicker
                colorTabs={[
                  {
                    label: "base",
                    colors: [
                      COLORS.white.value,
                      COLORS.black.value,
                      COLORS.paleGrey.value,
                      COLORS.moleGrey.value,
                      COLORS.convoyGrey.value,
                      COLORS.red.value,
                      COLORS.kellyGreen.value,
                      COLORS.royalBox.value,
                      COLORS.marine.value,
                    ],
                    cb: setLightContrast,
                  },
                  {
                    label: "contrast",
                    colors: [
                      COLORS.white.value,
                      COLORS.black.value,
                      COLORS.paleGrey.value,
                      COLORS.moleGrey.value,
                      COLORS.convoyGrey.value,
                      COLORS.red.value,
                      COLORS.kellyGreen.value,
                      COLORS.royalBox.value,
                      COLORS.marine.value,
                    ],
                    cb: setDarkContrast,
                  },
                  {
                    label: "stitching",
                    colors: [
                      COLORS.white.value,
                      COLORS.black.value,
                      COLORS.paleGrey.value,
                      COLORS.moleGrey.value,
                      COLORS.convoyGrey.value,
                      COLORS.red.value,
                      COLORS.kellyGreen.value,
                      COLORS.royalBox.value,
                      COLORS.marine.value,
                    ],
                    cb: setStitching,
                  },
                  {
                    label: "patch",
                    colors: [
                      COLORS.white.value,
                      COLORS.black.value,
                      COLORS.paleGrey.value,
                      COLORS.moleGrey.value,
                      COLORS.convoyGrey.value,
                      COLORS.red.value,
                      COLORS.kellyGreen.value,
                      COLORS.royalBox.value,
                      COLORS.marine.value,
                    ],
                    protective: [
                      COLORS.blackProtective.value,
                      COLORS.grayProtective.value,
                    ],
                    cb: setPatchColor,
                  },
                ]}
                setSelectedPart={setSelectedPart}
                selectedPart={selectedPart}
                tabs={[baseTab, contrastTab, patchTab, stitchingTab]}
                azomo={setAzomo}
              />
            </Grid>
          </Grid>
          {/* ////// MATERIAL /////////////// */}
          <Grid className={classes.specs} item sm={12} lg={12} md={12} xl={12} xs={12}>
            <Typography className={classes.specsText} align="center" variant="h4">
              Material
            </Typography>
            <Divider style={{marginTop: "10px", paddingTop: "3px"}} />
            <Typography align="center" variant="subtitle1">
              Bitte wählen Sie eine Variante. Im Preis inbegriffen.
            </Typography>
          </Grid>
          {/* // OPTIONS */}
          {/* ADDITIONAL ELEMENTS CONTAINER START */}{" "}
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="Material"
              name="Material"
              value={material}
              onChange={handleMaterial}
            >
              <Box display="flex" flexDirection="row" p={1} m={1}>
                <Card raised={true} style={{marginRight: 15}}>
                  <CardContent>
                    <FormControlLabel
                      value="Ripstop"
                      control={<Radio />}
                      label="Ripstop"
                    />
                  </CardContent>
                  <CardActions>
                    <img
                      style={{
                        align: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      alt=""
                      src={mat1}
                    ></img>
                  </CardActions>
                </Card>

                <Card raised={true}>
                  <CardContent>
                    <FormControlLabel
                      value="Escelade"
                      control={<Radio />}
                      label="Escelade"
                    />
                  </CardContent>
                  <CardActions>
                    <img
                      style={{
                        align: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      alt=""
                      src={mat2}
                    ></img>
                  </CardActions>
                </Card>
              </Box>
            </RadioGroup>
          </FormControl>
          {/* ///// MATERIAL END //////////// */}
          <Grid className={classes.specs} item sm={12} lg={12} md={12} xl={12} xs={12}>
            <Typography className={classes.specsText} align="center" variant="h4">
              Zusatzoptionen
            </Typography>
            <Divider style={{marginTop: "10px", paddingTop: "3px"}} />
            <Typography align="center" variant="subtitle1">
              Es können mehrere Optionen ausgewählt werden.
            </Typography>
          </Grid>
          {/* ADDITIONAL ELEMENTS CONTAINER START */}{" "}
          <Grid className={classes.extraOptions} container spacing={2}>
            {extras.map((option, id) => {
              return (
                <Grid key={id} item lg={6} md={6} xl={6} sm={6} xs={12}>
                  <Button
                    onClick={option.fun}
                    variant="text"
                    fullWidth
                    align="center"
                    size="small"
                  >
                    <Card raised={true} className={classes.card}>
                      <CardContent>
                        <Typography
                          noWrap
                          variant="subtitle2"
                          align="center"
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          <Switch checked={option.val} />
                          {option.name}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <img
                          style={{
                            align: "center",
                            width: "100%",
                            height: "100%",
                          }}
                          alt=""
                          src={option.img}
                        ></img>
                      </CardActions>
                    </Card>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
          {/*  ADDITIONAL ELEMENTS CONTAINER END   */}
          <Grid className={classes.specs} item sm={12} lg={12} md={12} xl={12} xs={12}>
            <Typography className={classes.specsText} align="center" variant="h4">
              Logo - Stickerei
            </Typography>

            <Divider style={{marginTop: "10px", paddingTop: "3px"}} />
            <Typography align="center" variant="subtitle1">
              Grösse des Logos bis 50cm². Grössere Stickerei auf Anfrage.
            </Typography>
            <Typography align="center" variant="subtitle1">
              Preis pro Platzierung: ab CHF 4.90
            </Typography>
          </Grid>
          {/* LOGO CONTAINER STICKERS START */}
          <Grid className={classes.extraOptions} container spacing={2}>
            {stickerOptions.map((option, id) => {
              return (
                <Grid key={id} item lg={6} md={6} xl={6} sm={6} xs={12}>
                  <Button
                    onClick={option.fun}
                    variant="text"
                    fullWidth
                    align="center"
                    size="small"
                  >
                    <Card raised={true} className={classes.card}>
                      <CardContent>
                        <Typography
                          noWrap
                          variant="subtitle2"
                          align="center"
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          <Switch checked={option.val} />
                          {option.name}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <img
                          style={{
                            align: "center",
                            width: "100%",
                            height: "100%",
                          }}
                          alt=""
                          src={option.img}
                        ></img>
                      </CardActions>
                    </Card>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
          {/* LOGO CONTAINER STICKERS END */}
          <Grid className={classes.specs} item sm={12} lg={12} md={12} xl={12} xs={12}>
            <Typography className={classes.specsText} align="center" variant="h4">
              Gewünschte Menge
            </Typography>
            <Divider style={{marginTop: "10px", paddingTop: "3px"}} />
            <Typography align="center" variant="subtitle1">
              Mindestproduktionsmenge 30 Stück
            </Typography>
          </Grid>
          <Grid className={classes.specs} item sm={12} lg={12} md={12} xl={12} xs={12}>
            <form
              onSubmit={(e) => handleAdd(e)}
              className={classes.quantity}
              autoComplete="off"
            >
              <Grid container>
                <Grid item sm={3} lg={3} md={3} xl={3} xs={3}></Grid>
                <Grid item sm={6} lg={6} md={6} xl={6} xs={6}>
                  <TextField
                    onChange={(e) => setQuantity(Number(e.target.value))}
                    type="number"
                    inputProps={{
                      min: 30,
                      defaultValue: 30,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    id="standard-basic"
                    label="Desired Menge"
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={3} lg={3} md={3} xl={3} xs={3}></Grid>
              </Grid>
            </form>
            <Typography variant="subtitle1">
              {parseFloat(PRICE.ultimatetrousers).toFixed(2)} CHF pro Stück
              (Mindestbestellmenge: 30 Stück)
            </Typography>
          </Grid>
        </Grid>
        <Grid align="center" item sm={1} lg={1} md={1} xl={1} xs={1}></Grid>
      </Grid>
      {/* FOOTHER PRICE START */}
      <Grid
        className={classes.footer}
        align="center"
        item
        sm={12}
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >
        <Typography style={{color: "white"}} variant="h6">
          Die angezeigten Preise in CHF gelten als Richtpreise.
        </Typography>
        <Typography style={{color: "white"}} variant="h6">
          Bitte senden Sie uns Ihre Anfrage!
        </Typography>
        <Typography style={{color: "white"}} variant="subtitle1">
          (ohne MwSt.)
        </Typography>
        <Divider
          style={{
            background: "#DE2D47",
            marginTop: "10px",
            paddingTop: "3px",
          }}
        />
        <Grid container>
          <Grid item sm={3} lg={3} md={3} xl={3} xs={3}></Grid>
          <Grid item sm={6} lg={6} md={6} xl={6} xs={6}>
            <Grid container>
              <Grid item sm={3} lg={3} md={3} xl={3} xs={3}>
                <Typography
                  noWrap
                  align="left"
                  style={{color: "white"}}
                  variant="subtitle1"
                >
                  Menge
                </Typography>
                <Typography
                  style={{color: "white"}}
                  align="left"
                  variant="subtitle1"
                  noWrap
                >
                  Preis pro Artikel
                </Typography>
                <Typography
                  style={{color: "#DE2D47"}}
                  align="left"
                  variant="subtitle1"
                  noWrap
                >
                  Total:
                </Typography>
                <Typography
                  style={{color: "#DE2D47"}}
                  align="left"
                  variant="subtitle1"
                  noWrap
                >
                  Extra
                </Typography>
                <Typography
                  style={{color: "white"}}
                  align="left"
                  variant="subtitle1"
                  noWrap
                >
                  Knieverstärkung (Cordura)
                </Typography>
                <Typography
                  style={{color: "white"}}
                  align="left"
                  variant="subtitle1"
                  noWrap
                >
                  Leuchtstreifen an den Beinen
                </Typography>
                <Typography
                  style={{color: "white"}}
                  align="left"
                  variant="subtitle1"
                  noWrap
                >
                  Stickerei auf Beintasche links
                </Typography>
                <Typography
                  style={{color: "white"}}
                  align="left"
                  variant="subtitle1"
                  noWrap
                >
                  Stickerei auf Geässtsache links
                </Typography>
                <Typography
                  style={{color: "#DE2D47"}}
                  align="left"
                  variant="subtitle1"
                  noWrap
                >
                  Total:
                </Typography>
              </Grid>
              <Grid item sm={6} lg={6} md={6} xl={6} xs={6}></Grid>
              <Grid item sm={3} lg={3} md={3} xl={3} xs={3}>
                <Typography
                  style={{color: "white"}}
                  align="right"
                  variant="subtitle1"
                  noWrap
                >
                  {quantityCheck()} Artikel
                </Typography>
                <Typography
                  style={{color: "white"}}
                  align="right"
                  variant="subtitle1"
                  noWrap
                >
                  {parseFloat(PRICE.ultimatetrousers).toFixed(2)} CHF
                </Typography>
                <Typography
                  style={{color: "#DE2D47"}}
                  align="right"
                  variant="subtitle1"
                  noWrap
                >
                  {parseFloat(quantityCheck() * PRICE.ultimatetrousers).toFixed(2)} CHF
                </Typography>
                <Typography
                  style={{color: "white"}}
                  align="right"
                  variant="subtitle1"
                  noWrap
                >
                  &zwnj;
                </Typography>
                <Typography
                  style={{color: "white"}}
                  align="right"
                  variant="subtitle1"
                  noWrap
                >
                  {corduraPrice !== 0
                    ? `${
                        quantityCheck() +
                        " x " +
                        parseFloat(PRICE.cordura).toFixed(2) +
                        " = "
                      }`
                    : null}
                  {parseFloat(corduraPrice).toFixed(2)} CHF
                </Typography>
                <Typography
                  style={{color: "white"}}
                  align="right"
                  variant="subtitle1"
                  noWrap
                >
                  {strips
                    ? `${
                        quantityCheck() +
                        " x " +
                        parseFloat(PRICE.reflectivestrips).toFixed(2) +
                        " = "
                      }`
                    : null}
                  {parseFloat(
                    strips ? quantityCheck() * PRICE.reflectivestrips : 0
                  ).toFixed(2)}{" "}
                  CHF
                </Typography>
                <Typography
                  style={{color: "white"}}
                  align="right"
                  variant="subtitle1"
                  noWrap
                >
                  {pocketLogo
                    ? `${
                        quantityCheck() +
                        " x " +
                        parseFloat(PRICE.logo).toFixed(2) +
                        " = "
                      }`
                    : null}
                  {parseFloat(pocketLogo ? quantityCheck() * PRICE.logo : 0).toFixed(2)}{" "}
                  CHF
                </Typography>
                <Typography
                  style={{color: "white"}}
                  align="right"
                  variant="subtitle1"
                  noWrap
                >
                  {backPocketLogo
                    ? `${
                        quantityCheck() +
                        " x " +
                        parseFloat(PRICE.logo).toFixed(2) +
                        " = "
                      }`
                    : null}
                  {parseFloat(backPocketLogo ? quantityCheck() * PRICE.logo : 0).toFixed(
                    2
                  )}{" "}
                  CHF
                </Typography>
                <Typography
                  style={{color: "#DE2D47"}}
                  align="right"
                  variant="subtitle1"
                  noWrap
                >
                  {parseFloat(
                    (pocketLogo ? quantityCheck() * PRICE.logo : 0) +
                      (backPocketLogo ? quantityCheck() * PRICE.logo : 0) +
                      (strips ? quantityCheck() * PRICE.reflectivestrips : 0) +
                      corduraPrice
                  ).toFixed(2)}{" "}
                  CHF
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={3} lg={3} md={3} xl={3} xs={3}></Grid>
        </Grid>

        <Divider
          style={{
            width: "50%",
            background: "#DE2D47",
            marginTop: "10px",
            paddingTop: "3px",
          }}
        />

        <Typography style={{color: "white", paddingTop: 15}} align="center" variant="h4">
          Gesamtpreis: {parseFloat(totalPrice).toFixed(2)}
        </Typography>
        <Link style={{width: "100%", height: "100%"}} to={"/cart"}>
          <Button
            onClick={(e) => handleAdd(e)}
            style={{padding: 10, margin: 15, width: "30%"}}
            color="secondary"
            variant="contained"
          >
            Submit
          </Button>
        </Link>
      </Grid>
      {/* FOOTHER PRICE END */}
      {/* MAIN GRID END*/}
      <Copyright />
    </Layout>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#D8D7DA",
    width: "100%",
    height: "100%",
    margin: "0px",
    padding: "0px",
    overflowX: "hidden",
  },
  paper: {
    height: 140,
    width: 100,
  },
  specs: {
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  preview: {
    height: 220,
    width: 220,
    padding: 10,
  },
  specsText: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  selectionPreview: {
    background: theme.palette.common.paper,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  extraOptions: {
    marginTop: 25,
    marginBottom: 25,
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  quantity: {display: "flex"},
  footer: {
    paddingTop: 25,
    background: "#000000",
    minHeight: "400px",
  },
  rotateButton: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  rButton: {
    display: "flex",
    width: "11%",
    height: "11%",
  },
}));
